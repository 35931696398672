<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商圈名称</span>
          <el-input v-model.trim="searchValue" class="searchInput" placeholder="商圈名称" clearable> </el-input>
        </el-col>
        <el-col :span="12">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>商圈列表</span>
        <el-button @click="handleAddDialog()" type="success" style="float: right; ">添加商圈</el-button>
      </div>
      <div style="margin: 14px 7px" v-if="$store.getters['user/isAllArea']">
        <span class="text">商圈对应城市</span>
        <el-select class="city" placeholder="请选择城市" @change="changeCity" v-model="areaId">
          <el-option v-if="areaId === -1" label="全国" :value="-1"></el-option>
          <el-option
            v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <el-table
        :data="firstLevel"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        default-expand-all
        header-cell-class-name="tableHeader"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column label="一级" align="center">
          <template slot-scope="scope">
            <div @click="firstClick(scope)">
              {{ scope.row.one }}
              <i v-if="!scope.row.isOped && scope.row.one" class="el-icon-arrow-down"></i>
              <i v-if="scope.row.isOped && scope.row.one" class="el-icon-arrow-up"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="二级" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="!isNaN(scope.row.second)">(</span>
              {{ scope.row.second }}
              <span v-if="!isNaN(scope.row.second)">)</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="storeCount" label="关联门店" align="center"> </el-table-column>
        <el-table-column prop="sortNumber" label="排序" align="center"> </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope)">编辑</el-button>
            <el-button
              type="text"
              class="deleteBtnText"
              @click="showDelete(scope)"
              v-if="scope.row.storeCount === '0' || scope.row.storeCount === 0"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 删除商圈的组件 -->
    <bm-deleteItem
      url="boom-center-product-service/sysAdmin/businessDistrict/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>

    <!-- 添加商圈的弹框 -->

    <el-dialog :close-on-click-modal="false" title="添加商圈" :visible.sync="addClassifyDialog" width="60%">
      <el-row :gutter="20">
        <el-col :span="12" class="addDistrict">
          <div class="el-transfer-panel">
            <p class="el-transfer-panel__header">
              <span class="el-checkbox__input "> <span class="el-checkbox__label"> 一级商圈 </span></span>
            </p>
            <div class="classifyBody">
              <div class="classifyItems">
                <div
                  v-for="(item, index) in firstLevelDialog"
                  :key="index"
                  class="classifyItem"
                  :class="{ activeClass: itemFirstId === item.id }"
                  @click="selectFirst(item.id)"
                >
                  {{ item.districtTitle }}
                </div>
              </div>

              <div>
                <el-input v-if="addFirst === true" class="className" v-model.trim="addFirstName"></el-input>
                <el-button v-if="addFirst === true" type="text" @click="addClassName">确定</el-button>

                <el-button v-else type="text" class="addClass" @click="addFirst = true">增加一级商圈</el-button>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="addDistrict"
          ><div>
            <div class="el-transfer-panel">
              <p class="el-transfer-panel__header">
                <span class="el-checkbox__input "> <span class="el-checkbox__label"> 二级商圈 </span></span>
              </p>
              <div class="classifyBody">
                <div class="classifyItems">
                  <div
                    v-for="(item, index) in secondClass"
                    :key="index"
                    class="classifyItem"
                    :class="{ activeClass: itemSecondId === item.id }"
                    @click="selectSecond(item.id)"
                  >
                    {{ item.districtTitle }}
                  </div>
                </div>

                <div>
                  <el-input v-if="addSecond === true" class="className" v-model.trim="secondName"></el-input>
                  <el-button v-if="addSecond === true" type="text" @click="addSecondClassName">确定</el-button>

                  <el-button v-else type="text" class="addClass" @click="addSecond = true">增加二级商圈</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="addClassifyDialog = false">关闭添加商圈</el-button>
      </span>
    </el-dialog>
    <!-- 编辑一级商圈 -->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑一级商圈"
      :visible.sync="firstDialog"
      width="25%"
      class="classifyDialog"
    >
      <el-form :rules="firstRules" ref="firstForm" prop="districtTitle" :model="firstForm" label-width="130px">
        <el-form-item label="商圈名称" prop="districtTitle">
          <el-input v-model.trim="firstForm.districtTitle"></el-input>
        </el-form-item>
        <el-form-item prop="sortNumber" label="商圈排序">
          <el-input-number
            v-model="firstForm.sortNumber"
            controls-position="right"
            :min="0"
            :step="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="是否显示">
          <el-switch v-model="firstForm.hideStatus" :active-value="0" :inactive-value="1"></el-switch>
        </el-form-item>
        <el-form-item label="是否过滤售罄商品">
          <el-switch v-model="firstForm.filterStatus" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="firstDialog = false">取消</el-button>
        <el-button round type="primary" @click="editProductCategory(0)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑二级，三级商圈 -->

    <el-dialog
      :close-on-click-modal="false"
      :title="secondTitle"
      :visible.sync="secondDialog"
      width="25%"
      class="classifyDialog"
    >
      <el-form ref="secondOrThirdForm" :rules="secondRules" :model="secondOrThirdForm" label-width="80px">
        <el-form-item label="所属商圈">
          <el-select v-model="secondOrThirdForm.parentId" placeholder="请选择所属商圈" disabled>
            <el-option
              v-for="(item, index) in firstLevel"
              :label="item.districtTitle"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商圈名称" prop="districtTitle">
          <el-input v-model.trim="secondOrThirdForm.districtTitle"></el-input>
        </el-form-item>
        <el-form-item label="商圈排序" prop="sortNumber">
          <el-input-number
            v-model="secondOrThirdForm.sortNumber"
            controls-position="right"
            :min="0"
            :step="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="是否显示">
          <el-switch v-model="secondOrThirdForm.hideStatus" :active-value="0" :inactive-value="1"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="secondDialog = false">取 消</el-button>
        <el-button type="primary" round @click="editProductCategory(1)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteRowItem } from '@/mixin/deleteRowItem'
export default {
  mixins: [deleteRowItem],
  data() {
    return {
      //删除商圈的
      timestamp: 0,
      // 添加商圈的名称
      secondName: '',
      thirdName: '',
      addFirstName: '',
      // 添加商圈弹框真假值决定是否显示输入框和确定按钮
      addFirst: false,
      addSecond: false,
      addThird: false,
      // 添加商圈数据展示列表
      secondClass: [],
      firstLevel: [],
      // 弹框点击一级或二级商圈的ID
      itemFirstId: '',
      itemSecondId: '',
      // 二级或者三级商圈弹框的标题
      secondTitle: '',
      firstRules: [],
      secondRules: [],
      firstLevelDialog: [],
      searchValue: '',
      // 是否显示弹框
      addClassifyDialog: false,
      firstDialog: false,
      secondDialog: false,
      // 编辑一级商圈的表单
      firstForm: {
        parentId: 0,
        sortNumber: 0,
        districtTitle: '',
        hideStatus: 0,
        filterStatus: 0
      },
      // 编辑二级或者三级商圈的表单
      secondOrThirdForm: {
        parentId: '',
        sortNumber: 0,
        districtTitle: '',
        hideStatus: 0
      },
      // 是否在编辑一级或者二级商圈
      isEditFirst: false,
      isEditSecond: false,
      searchValue: '',
      tempFirst: [],
      areaId: -1,
      headers: {}
    }
  },
  created() {
    if (this.$store.getters['user/currentAreaId'] == -1 && this.$store.getters['user/userAreaInfoListWithoutGlobal']) {
      this.areaId = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id
    } else {
      this.areaId = sessionStorage.currentAreaId
    }
    this.headers = {
      ['Bm-Area-Id']: this.areaId
    }
    this.getList()
    var that = this
    document.onkeydown = function(e) {
      var key = window.event.keyCode
      if (key == 13) {
        that.getList()
      }
    }
    this.getRules()
    // 获取表格列表的一级商圈 && 获取 添加商圈的一级商圈
    this.getList()
    this.productCategoryDialog()
  },
  methods: {
    changeCity() {
      this.headers = {
        ['Bm-Area-Id']: this.areaId
      }
      this.getList()
    },
    getRules() {
      this.firstRules = this.$initRules([
        {
          label: '商圈名称',
          value: 'districtTitle',
          type: 'input',
          required: true
        },
        {
          label: '商圈排序',
          value: 'sortNumber',
          type: 'input',
          required: true
        }
      ])

      this.secondRules = this.$initRules([
        {
          label: '商圈名称',
          value: 'districtTitle',
          type: 'input',
          required: true
        },
        {
          label: '商圈排序',
          value: 'sortNumber',
          type: 'input',
          required: true
        }
      ])
    },

    // =============处理表格的方法============

    // 点击一级商圈
    firstClick(scope) {
      if (!this.firstLevel[scope.$index].isOped) {
        ///如果是false的情况，就请求

        let arr = []
        let tempFirstArr = this.firstLevel
        this.tempFirst.forEach(item => {
          if (item.id === scope.row.id) {
            item.child.forEach(childItem => {
              childItem.level = 2
              childItem.second = childItem.districtTitle
              childItem.third = childItem.child.length
              childItem.isOped = false
              arr.push(childItem)
            })
            tempFirstArr.splice(scope.$index + 1, 0, ...arr)
            this.$set(this, 'firstLevel', tempFirstArr)
          }
        })

        this.firstLevel[scope.$index].isOped = true
      } else {
        // 如果是关闭的话，我就删除

        let a = this.firstLevel.slice(scope.$index + 1)
        let b = []
        for (let i = 0; i < a.length; i++) {
          console.log(a[i])
          if (a[i].level === 1) {
            break
          } else {
            b.push(a[i])
            continue
          }
        }
        this.firstLevel.splice(scope.$index + 1, b.length)
        this.firstLevel[scope.$index].isOped = false
        console.log(this.firstLevel[scope.$index].isOped)
      }
    },
    resetSearch() {
      this.searchValue = ''
      this.getList()
    },
    // 获取表格的一级商圈
    getList() {
      console.log(this.areaId)
      if (this.areaId == -1) {
        return
      }
      this.$api.vendor
        .vendor('businessDistrictList', {
          params: {
            search: this.searchValue
          },
          headers: this.headers
        })
        .then(res => {
          let { list } = res
          list.forEach(item => {
            item.level = 1
            item.one = item.districtTitle
            item.isOped = false
            item.second = parseInt(item.child.length) //转成数字，在表格内有用
            let a = 0
            for (let i = 0; i < item.child.length; i++) {
              a += item.child[i].child.length
            }
            item.third = a
          })
          this.firstLevel = list
          this.tempFirst = list
        })
        .catch(err => {
          this.$message.error(err.msg || '获取表格一级商圈失败')
        })
    },

    // =====================修改商圈的方法=====================

    handleAddDialog() {
      this.secondClass = []
      this.addClassifyDialog = true
      this.productCategoryDialog()
    },

    // 点击编辑按钮，根据值显示不同的编辑弹框
    edit(formVal) {
      console.log(formVal.row)
      if (formVal.row.level === 1) {
        this.isEditFirst = false
        this.firstDialog = true
        this.firstForm = { ...formVal.row }
      } else {
        this.secondTitle = '编辑二级商圈'
        this.secondDialog = true
        this.isEditSecond = false
        this.secondOrThirdForm = { ...formVal.row }
      }
    },

    // 修改商圈
    editProductCategory(val) {
      // 根据传入的id，判断是修改几级商圈
      if (val === 0) {
        this.$refs['firstForm'].validate((valid, validObj) => {
          if (!valid) {
            this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
            return true
          } else {
            this.$api.vendor
              .vendor(
                'putBusinessDistrict',
                {
                  districtTitle: this.firstForm.districtTitle,
                  id: this.firstForm.id.toString(),
                  parentId: this.firstForm.parentId,
                  sortNumber: parseInt(this.firstForm.sortNumber),
                  hideStatus: this.firstForm.hideStatus,
                  filterStatus: this.firstForm.filterStatus
                },
                { headers: this.headers }
              )
              .then(res => {
                this.$message.success('恭喜你，' + this.firstForm.districtTitle + '修改成功')
                this.productCategoryDialog()
                this.getList()
                this.firstDialog = false
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          }
        })
      } else {
        this.$refs['secondOrThirdForm'].validate((valid, validObj) => {
          if (!valid) {
            this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
            return true
          } else {
            this.$api.vendor
              .vendor(
                'putBusinessDistrict',
                {
                  districtTitle: this.secondOrThirdForm.districtTitle,
                  id: this.secondOrThirdForm.id,
                  parentId: this.secondOrThirdForm.parentId,
                  sortNumber: this.secondOrThirdForm.sortNumber,
                  hideStatus: this.secondOrThirdForm.hideStatus
                },
                { headers: this.headers }
              )
              .then(res => {
                this.$message.success('恭喜你，' + this.secondOrThirdForm.districtTitle + '修改成功')
                this.secondDialog = false
                this.productCategoryDialog()
                this.getList()
              })

              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          }
        })
      }
    },

    // =====================弹框方法=======================

    // 弹框添加一级商圈
    addClassName() {
      if (!this.addFirstName) {
        this.$message.error('商圈名不能为空')
        return
      }

      this.$api.vendor
        .vendor(
          'postBusinessDistrict',
          {
            districtTitle: this.addFirstName,
            parentId: 0,
            hideStatus: 0,
            rootId: 0,
            filterStatus: 0
          },
          { headers: this.headers }
        )
        .then(res => {
          this.addFirst = false //  取消输入框和确定
          this.addFirstName = '' // 清楚输入框的值
          this.productCategoryDialog()

          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '添加一级商圈失败')
        })
    },

    // 弹框添加二级商圈
    addSecondClassName() {
      if (!this.secondName) {
        this.$message.error('商圈名不能为空')
        return
      }
      this.$api.vendor
        .vendor(
          'postBusinessDistrict',
          {
            districtTitle: this.secondName,
            parentId: this.itemFirstId,
            rootId: this.itemFirstId,
            hideStatus: 0,
            filterStatus: 0
          },
          { headers: this.headers }
        )
        .then(res => {
          this.addSecond = false
          this.secondName = ''
          this.getList()
          this.selectFirst(this.itemFirstId)
        })
        .catch(err => {
          this.$message.error(err.msg || '添加二级商圈失败')
        })
    },

    // 向后端发起请求获取弹框一级商圈
    productCategoryDialog() {
      this.$api.vendor
        .vendor('businessDistrictList', { headers: this.headers })
        .then(res => {
          let { list } = res
          if (list.length) {
            this.firstLevelDialog = list
            this.selectFirst(list[0].id)
          } else {
            this.firstLevelDialog = []
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '获取一级商圈失败')
        })
    },
    // 向后端发起请求获取弹框二级商圈
    selectFirst(id) {
      this.$api.vendor
        .vendor('businessDistrictListId', id, { headers: this.headers })
        .then(res => {
          let list = res
          this.itemSecondId = ''
          this.itemFirstId = id
          if (list.length) {
            this.secondClass = list
          } else {
            this.secondClass = []
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '获取二级商圈失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.classifyBody {
  text-align: center;
  height: 300px;
  .classifyItems {
    line-height: 30px;
    cursor: grab;
    height: 270px;
    overflow-y: scroll;
    width: calc(100% + 18px);
  }

  .className {
    width: 80%;
    margin: 0 5px;
  }
  .activeClass {
    color: @color-primary-select;
    background: #f5f5f5;
  }

  .addClass {
    position: absolute;
    bottom: 0;
    left: calc(50% - 40px);
    width: 80px;
  }
}

/deep/.el-input--small .el-input__inner {
  height: 28px;
}
.addDistrict {
  display: flex;
  justify-content: center;
}
.el-transfer-panel .el-transfer-panel__header {
  margin: 0;
  text-align: center;
  padding-left: 0;
}

.classifyDialog {
  .el-select,
  .el-input-number,
  .el-input {
    width: 300px;
  }
}

.tableContainer {
  .text {
    margin-right: 10px;
  }
  .city {
    width: 100px;
  }
}
</style>
